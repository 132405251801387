import isEmpty from "is-empty";
import Web3 from "web3";

export const validation = (values) => {
  let { poolAmount, monthlyReward, usdAmount } = values;
  let errors = {};

  if (isEmpty(poolAmount)) {
    errors.poolAmount = "Required plan amount";
  }
  if (isEmpty(monthlyReward)) {
    errors.monthlyReward = "Required monthly reward";
  }
  if (isEmpty(usdAmount)) {
    errors.usdAmount = "Required USDT amount";
  } else {
  }

  return errors;
};

export const validation1 = (values) => {
  let { planId, user, referrer } = values;
  let errors = {};

  if (isEmpty(planId)) {
    errors.planId = "Required plan";
  }

  if (isEmpty(user)) {
    errors.user = "Required user address";
  } else if (!Web3.utils.isAddress(user)) {
    errors.user = "Invalid user address";
  }

  if (isEmpty(referrer)) {
    errors.referrer = "Required referral address";
  } else if (!Web3.utils.isAddress(referrer)) {
    errors.referrer = "Invalid referral address";
  }

  return errors;
};

export const validation2 = (values) => {
  let { planId1, user1 } = values;
  let errors = {};

  if (isEmpty(planId1)) {
    errors.planId1 = "Required plan";
  }

  if (isEmpty(user1)) {
    errors.user1 = "Required user address";
  } else if (!Web3.utils.isAddress(user1)) {
    errors.user1 = "Invalid user address";
  }

  return errors;
};
