import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Web3 from "web3";
import copy from "copy-to-clipboard";
import { useParams } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import DefiqStakeABI from "../ABI/StakeContract.json";
import BEP20ABI from "../ABI/USDTCOntract.json";
import DefiqTokenABI from "../ABI/DefiQContract.json";
import config from "../config/config.js";
import { toastAlert } from "../helper/toastAlert.js";
import { connection } from "../helper/connection.js";
import { useSelector } from "react-redux";
import { getPoolDetails, RegUserApi, ReStakeApi } from "../Action/apiAction.js";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Staking() {
  const { id } = useParams();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loading, setLoading] = useState(false);
  const [poolData, setPoolData] = useState([]);
  const [referral, setReferal] = useState(id);
  const [levWisC, setLevWisC] = useState([]);
  const [refVolumns, setRefVolumns] = useState([]);
  const [selectedPoolId, setSelectedPoolId] = useState(0);

  // useEffect(() => {
  //   if (
  //     JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
  //   ) {
  //     window.location.href = "/";
  //   }
  // }, []);

  useEffect(() => {
    getPoolDetail();
    // LevelWiseCount();
  }, [walletConnection.address]);

  // async function LevelWiseCount() {
  //   try {
  //     if (
  //       walletConnection &&
  //       walletConnection.address &&
  //       walletConnection.address != undefined &&
  //       walletConnection.address != null &&
  //       walletConnection.address != ""
  //     ) {
  //       walletConnection.address = "0xBc5e9712513ebEbEB00ebb3AbEBAc2950af0c0b6";
  //       let levCounts = [];
  //       let refVolums = [];
  //       let web3 = walletConnection.web3;
  //       var DefiqStakeCont = new web3.eth.Contract(
  //         DefiqStakeABI,
  //         config.DefiqStakeAddrs
  //       );
  //       for (let i = 0; i < 10; i++) {
  //         let levWiseCnt = await DefiqStakeCont.methods
  //           .levelWiseCount(walletConnection.address, i.toString())
  //           .call();
  //         levCounts.push({ level: i + 1, value: levWiseCnt });
  //       }
  //       console.log(levCounts, "levCounts");
  //       setLevWisC(levCounts);

  //       let userRefs = await DefiqStakeCont.methods
  //         .viewUserReferal(walletConnection.address)
  //         .call();
  //       console.log(userRefs, "userRefs");
  //       for (let j = 0; j < userRefs.length; j++) {
  //         let refVol = await DefiqStakeCont.methods
  //           .teamVolume(userRefs[j])
  //           .call();
  //         const isExisting = refVolums.some(
  //           (item) => item.referals === userRefs[j]
  //         );
  //         if (!isExisting) {
  //           refVolums.push({ referals: userRefs[j], volumn: refVol });
  //         }
  //         // refVolums.push({ referals: userRefs[j], volumn: refVol });
  //       }
  //       console.log(refVolums, "refVolums");
  //       setRefVolumns(refVolums);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const getPoolDetail = async () => {
    if (walletConnection.address) {
      let web3 = walletConnection.web3;
      const data = await getPoolDetails();
      let DefiQContract = new web3.eth.Contract(
        DefiqStakeABI,
        config.DefiqStakeAddrs
      );
      const details = await Promise.all(
        data?.data?.map(async (elem, i) => {
          var isCheckPool = await DefiQContract.methods
            .userPoolInfoLength(walletConnection.address, i)
            .call();
          return {
            ...elem,
            poolLength: isCheckPool
          };
        })
      );

      console.log(details, "isCheckPool");

      setPoolData(details);
    }
  };

  const stakeModalOpen = async (poolId) => {
    setSelectedPoolId(poolId);
  };

  const handleStakeNow = async (planId) => {
    try {
      setLoading(true);
      var get = await connection();
      if (get && get.web3) {
        var web3 = get.web3;
        var address = get.address;
        console.log(referral, "referral");

        if (referral == "" || referral == undefined) {
          setLoading(false);
          toastAlert("error", "Enter referral address");
          return false;
        }
        if (!web3.utils.isAddress(referral)) {
          setLoading(false);
          toastAlert("error", "Invalid referral address");
          return false;
        }
        let DefiQContract = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );
        let isUser = await DefiQContract.methods.users(address).call();
        if (isUser?.isExist) {
          setLoading(false);
          toastAlert("error", "User already exits");
          return false;
        }
        let isReferrer = await DefiQContract.methods.users(referral).call();
        console.log(isReferrer, "isReferrer");
        if (!isReferrer?.isExist) {
          setLoading(false);
          toastAlert("error", "Referral address not exits");
          return false;
        }
        let poolInfo = await DefiQContract.methods.poolInfo(planId).call();
        console.log(poolInfo, "poolInfo");
        if (poolInfo.status == false) {
          setLoading(false);
          toastAlert("error", "Plan not found");
          return false;
        }
        let PlanAmount = poolInfo?.poolAmount;

        let DefiqContract = new web3.eth.Contract(
          DefiqTokenABI,
          config.DefiQToken
        );
        let DefiQToken = await DefiqContract.methods
          .balanceOf(address.toString())
          .call();
        DefiQToken = DefiQToken / 1e18;
        console.log(DefiQToken, Number(PlanAmount) / 1e18, "checkBalance");
        if (Number(DefiQToken) < Number(PlanAmount) / 1e18) {
          setLoading(false);
          toastAlert("error", "Insufficient Balance (DefiQ)");
          return false;
        }
        let DefiqTokenContract = new web3.eth.Contract(
          DefiqTokenABI,
          config.DefiQToken
        );

        await DefiqTokenContract.methods
          .approve(config.DefiqStakeAddrs, PlanAmount.toString())
          .send({ from: address.toString() })
          .then(async (approveRes) => {
            if (approveRes.status == true && approveRes.transactionHash) {
              let _referrer = referral;
              let poolID = Number(planId);

              await DefiQContract.methods
                .regUser(_referrer, poolID.toString())
                .send({ from: address })
                .then(async (registerRes) => {
                  if (
                    registerRes.status == true &&
                    registerRes.transactionHash
                  ) {
                    const UserD = await DefiQContract.methods
                      .users(address)
                      .call()
                      .then(async (resp) => {
                        console.log(resp, "resp");
                        const payload = {
                          referal: _referrer,
                          user: address,
                          pid: poolID,
                          poolid: 1,
                          currUserID: resp?.id,
                          hash: registerRes.transactionHash
                        };
                        console.log(payload, "payload");
                        const saveData = await RegUserApi(payload);
                        if (saveData?.data?.status) {
                          toastAlert("success", "Stake Successfully Completed");
                          setTimeout(() => {
                            window.location.href = "/mystaking";
                          }, 2500);
                          return false;
                        }
                      });
                  } else {
                    setLoading(false);
                    toastAlert("error", "Register failed, try again later");
                    return false;
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  toastAlert("error", "Catch error, try again later");
                  return false;
                });
            } else {
              setLoading(false);
              toastAlert("error", "Approve failed, please try again");
              return false;
            }
          })
          .catch((err) => {
            setLoading(false);
            toastAlert("error", "Catch error, try again later");
            return false;
          });
      } else {
        setLoading(false);
        toastAlert("error", "Connect your wallet");
        return false;
      }
    } catch (err) {
      console.log("errrr: ", err);
      setLoading(false);
    }
  };

  const handleSatekAgain = async (planId) => {
    try {
      setLoading(true);
      var get = await connection();
      if (get && get.web3) {
        var web3 = get.web3;
        var address = get.address;

        // if (planId == "") {
        //   setLoading(false);
        //   toastAlert("error", "Plan id required");
        //   return false;
        // }
        let DefiQContract = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );
        let poolInfoL = await DefiQContract.methods
          .userPoolInfoLength(address, planId.toString())
          .call();
        console.log(poolInfoL, "poolInfoL");
        let poolInfo = await DefiQContract.methods.poolInfo(planId).call();
        console.log(poolInfo, "poolInfo");
        if (poolInfo && poolInfo.status == false) {
          setLoading(false);
          toastAlert("error", "Plan not found");
          return false;
        }
        let PlanAmount = poolInfo?.poolAmount;
        //getusdtBalance
        let DefiqContract = new web3.eth.Contract(
          DefiqTokenABI,
          config.DefiQToken
        );
        let DefiQToken = await DefiqContract.methods
          .balanceOf(address.toString())
          .call();
        DefiQToken = DefiQToken / 1e18;
        console.log(DefiQToken, Number(PlanAmount) / 1e18, "checkBalance");
        if (Number(DefiQToken) < Number(PlanAmount) / 1e18) {
          setLoading(false);
          toastAlert("error", "Insufficient Balance (DefiQ)");
          return false;
        }
        //approve
        let DefiqTokenContract = new web3.eth.Contract(
          DefiqTokenABI,
          config.DefiQToken
        );

        await DefiqTokenContract.methods
          .approve(config.DefiqStakeAddrs, PlanAmount.toString())
          .send({ from: address.toString() })
          .then(async (approveRes) => {
            if (approveRes.status == true && approveRes.transactionHash) {
              //join plan

              let poolId = Number(planId);

              await DefiQContract.methods
                .reJoinUser(poolId.toString())
                .send({ from: address })
                .then(async (registerRes) => {
                  if (
                    registerRes.status == true &&
                    registerRes.transactionHash
                  ) {
                    const UserD = await DefiQContract.methods
                      .users(address)
                      .call();
                    const payload = {
                      referal: UserD.refererAddress,
                      user: address,
                      pid: poolId,
                      poolid: Number(poolInfoL) + 1,
                      hash: registerRes.transactionHash
                    };
                    console.log(payload, "payload");
                    const saveData = await ReStakeApi(payload);
                    if (saveData?.data?.status) {
                      // setLoading(false);
                      toastAlert("success", "Re-Stake Successfully Completed");
                      setTimeout(() => {
                        window.location.href = "/mystaking";
                      }, 5000);
                      return false;
                    }
                  } else {
                    setLoading(false);
                    toastAlert("error", "Stake failed, try again later");
                    return false;
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  toastAlert("error", "Catch error, try again later");
                  return false;
                });
            } else {
              setLoading(false);
              toastAlert("error", "Approve failed, please try again");
              return false;
            }
          })
          .catch((err) => {
            setLoading(false);
            toastAlert("error", "Catch error, try again later");
            return false;
          });
      } else {
        setLoading(false);
        toastAlert("error", "Connect your wallet");
        return false;
      }
    } catch (err) {
      console.log("errrr: ", err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="page_box pb-5">
        <div className="container">
          <div className="head_box mb-5">
            <h3>Staking</h3>
          </div>
          <div className="staking">
            <div className="row">
              {poolData.length > 0 ? (
                poolData.map((elem, i) => {
                  return (
                    <div className="col-lg-4" key={i}>
                      <div className="plan_box">
                        <h5 className="mb-0">Plan {i}</h5>
                      </div>
                      <div className="box mb-4">
                        <div className="inbox mb-2 font">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={require("../assets/images/defiq.png")}
                              class="img-fluid"
                              alt="img"
                            />
                            <span>DeFiQ</span>
                          </div>
                          <p>
                            <span className="me-1">{elem.poolUsdtAmount}</span>
                            USD
                          </p>
                        </div>
                        <div className="inbox mb-2">
                          <p>Subscription Amount</p>
                          <p>
                            <span className="me-1">{elem.poolAmount}</span>
                            DeFiQ
                          </p>
                        </div>
                        <div className="inbox mb-3">
                          <p>Monthly Staking Rewards (%)</p>
                          <p>
                            <span>{elem.monthlyReward}%</span>
                          </p>
                        </div>
                        {!walletConnection?.userDetails.isExist ? (
                          <button
                            className="primary_btn w-100"
                            onClick={() => stakeModalOpen(elem.poolId)}
                            data-bs-toggle="modal"
                            data-bs-target="#stake_now_modal"
                          >
                            {" "}
                            Stake Now
                          </button>
                        ) : (
                          <button
                            className="primary_btn w-100"
                            onClick={() => handleSatekAgain(elem.poolId)}
                          >
                            {" "}
                            Stake Again
                          </button>
                        )}

                        <div class="accordion" id={`accordionExample${i}`}>
                          <div class="accordion-item bg-transparent border-0">
                            <h2 class="accordion-header" id={`headingOne${i}`}>
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne${i}`}
                                aria-expanded="true"
                                aria-controls={`collapseOne${i}`}
                              >
                                <p className="hide">Hide</p>
                                <p className="detail">Details</p>
                              </button>
                            </h2>
                            <div
                              id={`collapseOne${i}`}
                              class="accordion-collapse collapse show"
                              aria-labelledby={`headingOne${i}`}
                              data-bs-parent={`#accordionExample${i}`}
                            >
                              <div class="accordion-body p-0">
                                <div className="inbox d-block">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Staking Rewards</p>
                                    <p>
                                      <span>
                                        {/* {((elem.poolAmount *
                                          elem.monthlyReward) /
                                          100) *
                                          12} */}
                                        {elem.monthlyReward * 12}%
                                      </span>
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p> Duration (Days)</p>
                                    <p>
                                      <span>360 </span>Days
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h5 className="text-center info">
                  Connect Your Wallet than proceed staking process
                </h5>
              )}

              {/* <div className="col-lg-4">
                <div className="plan_box">
                  <h5 className="mb-0">Plan B</h5>
                </div>
                <div className="box mb-4">
                  <div className="inbox mb-2 font">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={require("../assets/images/defiq.png")}
                        class="img-fluid"
                        alt="img"
                      />
                      <span>DeFiQ</span>
                    </div>
                    <p>
                      <span className="me-1">1234.12</span>USD
                    </p>
                  </div>
                  <div className="inbox mb-2">
                    <p>Subscription Amount</p>
                    <p>
                      <span className="me-1">1000</span>DeFiQ
                    </p>
                  </div>
                  <div className="inbox mb-3">
                    <p>Monthly ROI Percentage (%)</p>
                    <p>
                      <span>3%</span>
                    </p>
                  </div>
                  <button className="primary_btn w-100"> Stake Now</button>
                  <div class="accordion" id="accordionExample1">
                    <div class="accordion-item bg-transparent border-0">
                      <h2 class="accordion-header" id="headingOne1">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne1"
                          aria-expanded="false"
                          aria-controls="collapseOne1"
                        >
                          <p className="hide">Hide</p>
                          <p className="detail">Details</p>
                        </button>
                      </h2>
                      <div
                        id="collapseOne1"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne1"
                        data-bs-parent="#accordionExample1"
                      >
                        <div class="accordion-body p-0">
                          <div className="inbox d-block">
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Interest</p>
                              <p>
                                <span>36%</span>
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <p> Duration (Days)</p>
                              <p>
                                <span>360 </span>Days
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="plan_box">
                  <h5 className="mb-0">Plan C</h5>
                </div>
                <div className="box mb-4">
                  <div className="inbox mb-2 font">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={require("../assets/images/defiq.png")}
                        class="img-fluid"
                        alt="img"
                      />
                      <span>DeFiQ</span>
                    </div>
                    <p>
                      <span className="me-1">1234.12</span>USD
                    </p>
                  </div>
                  <div className="inbox mb-2">
                    <p>Subscription Amount</p>
                    <p>
                      <span className="me-1">1000</span>DeFiQ
                    </p>
                  </div>
                  <div className="inbox mb-3">
                    <p>Monthly ROI Percentage (%)</p>
                    <p>
                      <span>3%</span>
                    </p>
                  </div>
                  <button className="primary_btn w-100"> Stake Now</button>
                  <div class="accordion" id="accordionExample2">
                    <div class="accordion-item bg-transparent border-0">
                      <h2 class="accordion-header" id="headingOne2">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne2"
                          aria-expanded="false"
                          aria-controls="collapseOne2"
                        >
                          <p className="hide">Hide</p>
                          <p className="detail">Details</p>
                        </button>
                      </h2>
                      <div
                        id="collapseOne2"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne2"
                        data-bs-parent="#accordionExample2"
                      >
                        <div class="accordion-body p-0">
                          <div className="inbox d-block">
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Interest</p>
                              <p>
                                <span>36%</span>
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <p> Duration (Days)</p>
                              <p>
                                <span>360 </span>Days
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            {/* <table border={"1"}>
              {levWisC.map((ele, i) => {
                return (
                  <tr>
                    {" "}
                    <td>{ele.level}</td>
                    <td>{ele.value}</td>
                  </tr>
                );
              })}
            </table>
            <table border={"2"} className="table">
              {refVolumns.map((ele, i) => {
                return (
                  <tr>
                    {" "}
                    <td>{ele.referals}</td>
                    <td>{ele.volumn}</td>
                  </tr>
                );
              })}
            </table> */}
          </div>
        </div>
      </div>
      {loading && (
        <div className="loadBefore">
          <PuffLoader
            color={"#9CFF45ED"}
            loading={loading}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <Footer />

      <div
        className="modal fade primary_modal"
        id="stake_now_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Stake Now
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <p>Referral Address</p>
                  <input
                    className="primary_input"
                    onChange={(e) => setReferal(e.target.value)}
                    value={referral}
                    disabled={id ? true : false}
                  />
                  {/* <span style={{ fontSize: "13px" }}>
                    Default ID : {config.AdminAddress}
                  </span>{" "} */}
                  {/* <i
                    class="fa-regular fa-copy cursor"
                    onClick={() => {
                      copy(config.AdminAddress);
                      toastAlert("success", "Copied Successfully");
                    }}
                  ></i> */}
                </div>

                <button
                  className="primary_btn w-100"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  onClick={() => handleStakeNow(selectedPoolId)}
                >
                  {" "}
                  Stake Now
                </button>
                {/* <div className="m-2 mb-3">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector) => (
                      <>
                        {connector && connector.id == "metaMask" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <div>
                              <button
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                type="button"
                                className="connect_wallet_button"
                                onClick={() => connectfunction({ connector })}
                              >
                                <img
                                  src={require("../assets/images/metamask.png")}
                                  height="28"
                                  width="28"
                                  className="img-fluid"
                                />{" "}
                                <span>MetaMask</span>
                              </button>
                            </div>
                          </a>
                        ) : (
                          ""
                        )}
                        {connector && connector.id == "walletConnect" ? (
                          <>
                            <a target="_blank" className="mb-3 d-block">
                              <button
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                type="button"
                                className="connect_wallet_button"
                                onClick={() => connectfunction({ connector })}
                              >
                                <img
                                  src={require("../assets/images/trustwallet.png")}
                                  height="28"
                                  width="28"
                                  className="img-fluid"
                                />{" "}
                                <span>Trust Wallet</span>
                              </button>
                            </a>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
