import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer.js";
import { convert } from "../helper/convert.js";
import config from "../config/config.js";
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import { connection } from "../helper/connection.js";
import DefiqStakeABI from "../ABI/DefiqStake.json";
import Navbar from "../components/Navbar.js";
import moment from "moment";
import PuffLoader from "react-spinners/PuffLoader";
import DataTable from "react-data-table-component";
import { validation, validation1, validation2 } from "./adminValidation.js";
import {
  get_plans,
  add_plan,
  updateDbData,
  getRefeUserData,
  AdminStakeApi,
  AdminReStake
} from "../Action/adminAction.js";
import { formatAddress, shortText } from "../helper/customMath.js";

var initialValues = {
  poolAmount: "",
  monthlyReward: "",
  usdAmount: ""
};

var initialValues1 = {
  planId: "",
  user: "",
  referrer: ""
};

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Admin(props) {
  const [load, setLoad] = useState(false);
  const [Error, setValidError] = useState({});
  const [values, setValues] = useState(initialValues);
  const { poolAmount, monthlyReward, usdAmount } = values;
  const [joinDatas, setjoinDatas] = useState(initialValues1);
  const { planId, user, referrer } = joinDatas;
  const [planId1, setplanId1] = useState("");
  const [user1, setuser1] = useState("");
  const [plans, setplans] = useState([]);

  const [cliamId, setCliamId] = useState(0);
  const [pId, setPid] = useState(0);
  const [addr, setAddr] = useState("");
  const [cliamDate, setClimDate] = useState();
  const [joinDate, setJoinDate] = useState();
  const [refDats, setRefDatas] = useState([]);

  const walletConnection = useSelector((state) => state.walletConnection);

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.SSNo
    },
    {
      name: "User Address",
      selector: (row) => halfAddrShow(row?._id)
    },
    {
      name: "Referral Count",
      selector: (row) => row.totalReferrals
    }
    // {
    //   name: "Hash",
    //   selector: (row) => (
    //     <a target="_blank" href={config.txurl + row.txHash}>
    //       {halfAddrShow(row.txHash)}
    //     </a>
    //   )
    // }
  ];

  useEffect(() => {
    getPlanDatas();
    getRefData();
  }, []);

  const getPlanDatas = async () => {
    let { data, status } = await get_plans();
    console.log(data, status, "data, status");
    if (status) {
      setplans(data);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setValues({ ...values, ...{ [name]: value } });
    setValidError({});
  };

  const handleChange1 = async (e) => {
    const { name, value } = e.target;
    setjoinDatas({ ...joinDatas, ...{ [name]: value } });
    setValidError({});
  };

  const handleChange2 = async (e) => {
    const { name, value } = e.target;
    setjoinDatas({ ...joinDatas, ...{ planId: value } });
    setValidError({});
  };

  const getRefData = async () => {
    const data = await getRefeUserData();
    console.log(data?.status?.result, "datadatadatadata");
    data?.status?.result.map((ele, i) => {
      ele.SSNo = i + 1;
    });
    setRefDatas(data?.status?.result);
  };

  const SubmitAddplan = async (e) => {
    e.preventDefault();
    var get = await connection();
    try {
      if (get && get.web3) {
        let errors = await validation(values);
        console.log(errors, "errors");
        if (isEmpty(errors)) {
          setValidError({});
          setLoad(true);
          var address = get.address;
          var web3 = get.web3;
          var contract = new web3.eth.Contract(
            DefiqStakeABI,
            config.DefiqStakeAddrs
          );
          let admin = await contract.methods.owner().call();
          // console.log("admaaaaaaaaaaaaain: ", admin);
          if (address.toLowerCase() == admin.toLowerCase()) {
            let poolLen = await contract.methods.getPoolLenght().call();
            console.log(poolLen, "poolLen");

            await contract.methods
              .addPool(
                poolAmount.toString(),
                monthlyReward.toString(),
                usdAmount.toString()
              )
              .send({ from: address })
              .then(async (res) => {
                if (res.status == true && res.transactionHash) {
                  let reqData = {
                    poolId: poolLen == 0 ? 0 : poolLen,
                    poolAmount: poolAmount,
                    monthlyReward: monthlyReward,
                    poolUsdtAmount: usdAmount,
                    hash: res.transactionHash
                  };
                  let { status } = await add_plan(reqData);
                  console.log(status, "dbDatadbData");
                  if (status) {
                    setLoad(false);
                    toastAlert("success", "Add plan successfully completed");
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  } else {
                    setLoad(false);
                    toastAlert("error", "Add plan failed, try again later");
                    return false;
                  }
                } else {
                  setLoad(false);
                  toastAlert("error", "Rejected, try again later");
                  return false;
                }
              })
              .catch((err) => {
                setLoad(false);
                toastAlert("error", "Catch error, try again later");
                return false;
              });
          } else {
            setLoad(false);
            return toastAlert(
              "error",
              "Please connect to admin wallet address"
            );
          }
        } else {
          setValidError(errors);
        }
      } else {
        setLoad(false);
        return toastAlert("error", "Please connect to admin wallet address");
      }
    } catch (err) {
      setLoad(false);
      console.log("err: ", err);
      return toastAlert("error", "Transaction Rejected");
    }
  };

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const Submitjoin = async (e) => {
    e.preventDefault();
    var get = await connection();
    try {
      if (get && get.web3) {
        let errors = await validation1(joinDatas);
        console.log(errors, "errors");
        if (isEmpty(errors)) {
          setValidError({});
          setLoad(true);
          var address = get.address;
          var web3 = get.web3;
          var contract = new web3.eth.Contract(
            DefiqStakeABI,
            config.DefiqStakeAddrs
          );
          let admin = await contract.methods.owner().call();
          // console.log("admaaaaaaaaaaaaain: ", admin);

          if (address.toLowerCase() == admin.toLowerCase()) {
            var isUser = await contract.methods.users(user).call();
            console.log(isUser, "isUserisUser");
            if (!isUser?.isExist) {
              var isReferrer = await contract.methods.users(referrer).call();
              if (isReferrer?.isExist) {
                //getpoolInfo
                let poolLen = await contract.methods.getPoolLenght().call();
                console.log(poolLen, "poolLen");
                if (Number(poolLen) > 0) {
                  let poolInfo = await contract.methods.poolInfo(planId).call();
                  console.log(poolInfo, "poolInfo");
                  if (poolInfo && poolInfo.status == true) {
                    await contract.methods
                      .regUserAdmin(
                        planId.toString(),
                        user.toString(),
                        referrer.toString()
                      )
                      .send({ from: address })
                      .then(async (res) => {
                        if (res.status == true && res.transactionHash) {
                          const UserD = await contract.methods
                            .users(user)
                            .call();
                          const payload = {
                            referal: referrer,
                            user: user,
                            pid: planId,
                            poolid: 1,
                            currUserID: UserD.id,
                            hash: res.transactionHash
                          };
                          console.log(payload, "payload");
                          const saveData = await AdminStakeApi(payload);
                          if (saveData?.data?.status) {
                            setLoad(false);
                            toastAlert(
                              "success",
                              "Join user successfully completed"
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 5000);
                          }
                        } else {
                          setLoad(false);
                          toastAlert(
                            "error",
                            "Join user failed, try again later"
                          );
                          return false;
                        }
                      })
                      .catch((err) => {
                        setLoad(false);
                        toastAlert("error", "Catch error, try again later");
                        return false;
                      });
                  } else {
                    setLoad(false);
                    toastAlert("error", "Plan not found");
                    return false;
                  }
                } else {
                  setLoad(false);
                  toastAlert("error", "No plan added");
                  return false;
                }
              } else {
                setLoad(false);
                toastAlert("error", "Invalid referral address");
                return false;
              }
            } else {
              setLoad(false);
              toastAlert("error", "User already exists");
              return false;
            }
          } else {
            setLoad(false);
            return toastAlert(
              "error",
              "Please connect to admin wallet address"
            );
          }
        } else {
          setValidError(errors);
        }
      } else {
        setLoad(false);
        return toastAlert("error", "Please connect to admin wallet address");
      }
    } catch (err) {
      setLoad(false);
      console.log("err: ", err);
      return toastAlert("error", "Transaction Rejected");
    }
  };

  const Submitre_join = async (e) => {
    e.preventDefault();
    var get = await connection();
    try {
      if (get && get.web3) {
        let rejoinjoinDatas = {
          planId1: planId1,
          user1: user1
        };
        let errors = await validation2(rejoinjoinDatas);
        console.log(errors, "errors");
        if (isEmpty(errors)) {
          setValidError({});
          setLoad(true);
          var address = get.address;
          var web3 = get.web3;
          var contract = new web3.eth.Contract(
            DefiqStakeABI,
            config.DefiqStakeAddrs
          );
          let admin = await contract.methods.owner().call();
          // console.log("admaaaaaaaaaaaaain: ", admin);

          if (address.toLowerCase() == admin.toLowerCase()) {
            var isUser = await contract.methods.users(user1.toString()).call();
            console.log(isUser, "isUserisUser");
            if (isUser?.isExist) {
              //getpoolInfo
              let poolLen = await contract.methods.getPoolLenght().call();
              console.log(poolLen, "poolLenwqerdwqrdewd", planId1);
              if (Number(poolLen) > 0) {
                let poolInfoL = await contract.methods
                  .userPoolInfoLength(user1, planId1.toString())
                  .call();
                console.log(poolInfoL, "poolInfoL", planId1);
                let poolInfo = await contract.methods
                  .poolInfo(planId1.toString())
                  .call();
                console.log(poolInfo, "poolInfo");
                if (poolInfo && poolInfo.status == true) {
                  await contract.methods
                    .reJoinAminUser(planId1.toString(), user1.toString())
                    .send({ from: address })
                    .then(async (res) => {
                      if (res.status == true && res.transactionHash) {
                        const payload = {
                          referal: isUser.refererAddress,
                          user: user1,
                          pid: planId1,
                          poolid: Number(poolInfoL) + 1,
                          hash: res.transactionHash
                        };
                        console.log(payload, "payload");
                        const saveData = await AdminReStake(payload);
                        if (saveData?.data?.status) {
                          setLoad(false);
                          toastAlert(
                            "success",
                            "Re-join user successfully completed"
                          );
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        }
                      } else {
                        setLoad(false);
                        toastAlert(
                          "error",
                          "Re-join user failed, try again later"
                        );
                        return false;
                      }
                    })
                    .catch((err) => {
                      setLoad(false);
                      toastAlert("error", "Catch error, try again later");
                      return false;
                    });
                } else {
                  setLoad(false);
                  toastAlert("error", "Plan not found");
                  return false;
                }
              } else {
                setLoad(false);
                toastAlert("error", "No plan added");
                return false;
              }
            } else {
              setLoad(false);
              toastAlert("error", "User not found");
              return false;
            }
          } else {
            setLoad(false);
            return toastAlert(
              "error",
              "Please connect to admin wallet address"
            );
          }
        } else {
          setValidError(errors);
        }
      } else {
        setLoad(false);
        return toastAlert("error", "Please connect to admin wallet address");
      }
    } catch (err) {
      setLoad(false);
      console.log("err: ", err);
      return toastAlert("error", "Transaction Rejected");
    }
  };

  const DateChnageFlow = async () => {
    console.log(
      cliamId,
      pId,
      addr,
      new Date(cliamDate).getTime() / 1000,
      new Date(joinDate).getTime() / 1000,
      "asdfsaI"
    );
    var get = await connection();
    try {
      if (get && get.web3) {
        setValidError({});
        setLoad(true);
        var address = get.address;
        var web3 = get.web3;
        var contract = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );

        await contract.methods
          .upadteDateForUserPool(
            cliamId.toString(),
            pId.toString(),
            addr,
            (new Date(cliamDate).getTime() / 1000).toString(),
            (new Date(joinDate).getTime() / 1000).toString()
          )
          .send({ from: address })
          .then(async (res) => {
            const payload = {
              cliamId,
              pId,
              addr,
              ciamDate: new Date(cliamDate).getTime() / 1000,
              JoinDate: new Date(joinDate).getTime() / 1000
            };
            const data = await updateDbData(payload);
            if (data) {
              setLoad(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoad(false);
          });
      }
    } catch (err) {
      console.log(err);
      setLoad(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="page_box">
        <section className="buytoken">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xl-6 mx-auto">
                <div className="buytokenbg">
                  <div className="head mw-100">
                    <h2 className="h2tag mb-4">ADD-PLAN</h2>
                  </div>

                  <div className="mb-4">
                    <label className="mb-2">Plan Amount :</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={handleChange}
                      name="poolAmount"
                      value={poolAmount}
                      onInput={validPositive}
                    />
                    {!isEmpty(Error.poolAmount) ? (
                      <span className="errortext">{Error?.poolAmount}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">Monthly Reward Percentage :</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={handleChange}
                      name="monthlyReward"
                      value={monthlyReward}
                      onInput={validPositive}
                    />
                    {!isEmpty(Error.monthlyReward) ? (
                      <span className="errortext">{Error?.monthlyReward}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">USDT Amount :</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={handleChange}
                      name="usdAmount"
                      value={usdAmount}
                      onInput={validPositive}
                    />
                    {!isEmpty(Error.usdAmount) ? (
                      <span className="errortext">{Error?.usdAmount}</span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="text-center d-flex justify-content-center">
                    {load == false &&
                    walletConnection &&
                    walletConnection.address &&
                    walletConnection.address !== "" ? (
                      <button
                        type="button"
                        className="primary_btn"
                        onClick={SubmitAddplan}
                      >
                        Submit
                      </button>
                    ) : walletConnection.address == "" ? (
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        Connect wallet
                      </button>
                    ) : load == true ? (
                      <button type="button" className="primary_btn" disabled>
                        Submit
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 mx-auto">
                <div className="buytokenbg">
                  <div className="head mw-100">
                    <h2 className="h2tag mb-4">JOIN-USER</h2>
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">Plan :</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={handleChange2}
                    >
                      <option>Select plan</option>
                      {plans &&
                        plans.length > 0 &&
                        plans?.map((items) => (
                          <option value={items.poolId}>
                            {items.poolAmount}
                          </option>
                        ))}
                    </select>
                    {!isEmpty(Error.planId) ? (
                      <span className="errortext">{Error?.planId}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">User address :</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={handleChange1}
                      name="user"
                      value={user}
                    />
                    {!isEmpty(Error.user) ? (
                      <span className="errortext">{Error?.user}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">Referral address :</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={handleChange1}
                      name="referrer"
                      value={referrer}
                    />
                    {!isEmpty(Error.referrer) ? (
                      <span className="errortext">{Error?.referrer}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center d-flex justify-content-center">
                    {load == false &&
                    walletConnection &&
                    walletConnection.address &&
                    walletConnection.address !== "" ? (
                      <button
                        type="button"
                        className="primary_btn"
                        onClick={Submitjoin}
                      >
                        Join
                      </button>
                    ) : walletConnection.address == "" ? (
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        Connect wallet
                      </button>
                    ) : load == true ? (
                      <button type="button" className="primary_btn" disabled>
                        Submit
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="buytoken">
          <div className="container">
            <div className="row">
              
            </div>
          </div>
        </section> */}
        <section className="buytoken">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xl-6 mx-auto">
                <div className="buytokenbg">
                  <div className="head mw-100">
                    <h2 className="h2tag mb-4">REJOIN-USER</h2>
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">Plan :</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setplanId1(e.target.value)}
                    >
                      <option>Select plan</option>
                      {plans &&
                        plans.length > 0 &&
                        plans?.map((items) => (
                          <option value={items.poolId}>
                            {items.poolAmount}
                          </option>
                        ))}
                    </select>
                    {!isEmpty(Error.planId1) ? (
                      <span className="errortext">{Error?.planId1}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="mb-2">User address :</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => setuser1(e.target.value)}
                      name="user1"
                      value={user1}
                    />
                    {!isEmpty(Error.user1) ? (
                      <span className="errortext">{Error?.user1}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center d-flex justify-content-center">
                    {load == false &&
                    walletConnection &&
                    walletConnection.address &&
                    walletConnection.address !== "" ? (
                      <button
                        type="button"
                        className="primary_btn"
                        onClick={Submitre_join}
                      >
                        Re-join
                      </button>
                    ) : walletConnection.address == "" ? (
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        Connect wallet
                      </button>
                    ) : load == true ? (
                      <button type="button" className="primary_btn" disabled>
                        Submit
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 mx-auto">
                <div className="history_table mt-3">
                  <div className="flx mb-4">
                    <h4>User Referral History</h4>
                    <div className="d-flex align-items-center gap-1"></div>
                  </div>
                  <DataTable
                    pagination
                    columns={columns}
                    data={refDats}
                    responsive
                  />
                </div>

                {/* <input
                  type="number"
                  placeholder="CliamId"
                  class="form-control"
                  onChange={(e) => setCliamId(e.target.value)}
                />
                <input
                  type="number"
                  class="form-control"
                  placeholder="PID"
                  onChange={(e) => setPid(e.target.value)}
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Address"
                  onChange={(e) => setAddr(e.target.value)}
                />
                <input
                  type="Date"
                  placeholder="Cliam Date"
                  class="form-control"
                  onChange={(e) => setClimDate(e.target.value)}
                />
                <input
                  type="Date"
                  class="form-control"
                  placeholder="Join Date"
                  onChange={(e) => setJoinDate(e.target.value)}
                />
                {load == false &&
                walletConnection &&
                walletConnection.address &&
                walletConnection.address !== "" ? (
                  <button
                    type="button"
                    className="primary_btn"
                    onClick={DateChnageFlow}
                  >
                    Submit
                  </button>
                ) : walletConnection.address == "" ? (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    Connect wallet
                  </button>
                ) : load == true ? (
                  <button type="button" className="primary_btn" disabled>
                    Processing...please wait
                  </button>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container">
            <div className="row">
              
            </div>
          </div>
        </section> */}
      </div>
      {load && (
        <div className="loadBefore">
          <PuffLoader
            color={"#9CFF45ED"}
            loading={load}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
