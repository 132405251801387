import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes
} from "react-router-dom";
//
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { bsc, bscTestnet, polygon, polygonMumbai } from "viem/chains";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { Provider } from "react-redux";
import store from "./store";
//
import { ToastContainer } from "react-toastify";
import Home from "./pages/home.js";
import Staking from "./pages/staking.js";
import Mystaking from "./pages/mystaking.js";
import Admin from "./pages/Admin";
import MystakingCheck from "./pages/mystaking _Check.js";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()]
);
// console.log({ chains }, 'chainschainschainswwwww')

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2"
      }
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/staking", element: <Staking /> },
    { path: "/staking/:id", element: <Staking /> },
    { path: "/mystaking", element: <Mystaking /> },
    { path: "/adminsetting", element: <Admin /> },
    { path: "/dashboard_Check/:addressparam", element: <MystakingCheck /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router basename="/">
        <WagmiConfig config={config}>
          <ToastContainer />
          <App />
        </WagmiConfig>
      </Router>
    </Provider>
  );
};

export default AppWrapper;
