import axios from "axios";
import config from "../config/config";

export const CliamStakeAmount = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/CliamStakeAmount`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const CliamLevelReward = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/CliamLevelReward`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const CliamReferalReward = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/CliamReferalReward`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const CliamDirectReward = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/CliamDirectReward`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const RegUserApi = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/RegUserApi`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const ReStakeApi = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/ReStakeApi`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getPoolDetails = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}api/routesUser/getPoolDetails`
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const getPoolUserInfo = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/getPoolUserInfo`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const getPoolUsers = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/getPoolUsers`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const home_datas = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}api/routesUser/home_datas`
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.data,
      status: resp?.data?.status
    };
  } catch (err) {
    console.log(err);
  }
};

export const get_withdraw_hist = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/get_withdraw_hist`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      directHist: resp?.data?.directHist,
      referralHist: resp?.data?.referralHist,
      status: resp?.data?.status
    };
  } catch (err) {
    console.log(err);
  }
};

export const getCliamedDefiQ = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/getCliamedDefiQ`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      result: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getROICliamHis = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/getROICliamHis`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const getUnStakeClaimHis = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/getUnStakeClaimHis`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data?.result
    };
  } catch (err) {
    console.log(err);
  }
};

export const checkValidEntry = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/checkValidEntry`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const checkValidEntry2 = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}api/routesUser/checkValidEntry2`,
      data
    });
    console.log(resp, "adcasfc");

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};


const apiUrl = 'https://api.bscscan.com/api'; // BscScan API endpoint

const params = {
  module: 'account',
  action: 'tokentx',
  address: '0x6E15A39C8Ebf50E1Eab7898891D36B7C22D0087f',
  startblock: 0,
  endblock: 'latest',
  sort: 'asc',
  apikey: 'FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN', // Replace with your BscScan API key
};

axios.get(apiUrl, { params })
  .then(response => {
    // Handle the response data here
    const transactions = response.data.result;
    console.log(transactions);
  })
  .catch(error => {
    console.error('Error fetching data from BscScan:', error);
  });