import axios from "axios";
import config from "../config/config";
const baseUrl = config.baseUrl;

export const AdminReStake = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${baseUrl}api/routesAdmin/AdminReStake`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const AdminStakeApi = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${baseUrl}api/routesAdmin/AdminStakeApi`,
      data
    });

    return {
      data: resp?.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const get_plans = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${baseUrl}api/routesAdmin/get_plans`
    });
    return {
      status: resp.data.status,
      data: resp.data.result
    };
  } catch (err) {
    return {
      errors: returnErr(err)
    };
  }
};

export const add_plan = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${baseUrl}api/routesAdmin/add_plan`,
      data: data
    });
    return {
      status: resp.data.status
    };
  } catch (err) {
    return {
      errors: returnErr(err)
    };
  }
};

export const updateDbData = async (data) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${baseUrl}api/routesAdmin/updateDbData`,
      data: data
    });
    return {
      status: resp.data.status
    };
  } catch (err) {
    return {
      errors: returnErr(err)
    };
  }
};

export const getRefeUserData = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${baseUrl}api/routesAdmin/getRefeUserData`
    });
    return {
      status: resp.data
    };
  } catch (err) {
    return {
      errors: returnErr(err)
    };
  }
};

function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}
