import ABI from "../ABI/BEP20ABI.json";
import { divideDecimal } from "../helper/customMath";
import { convert } from "../helper/convert";
import { connection } from "../helper/connection";

export async function getTokenBalance(tokenAddress) {
  //   console.log(tokenAddress, "tokenAddresstokenAddress");
  var get = await connection();
  try {
    if (get && get.web3 && get.address) {
      var address = get.address;
      var web3 = get.web3;
      var Contract = new web3.eth.Contract(ABI, tokenAddress);
      var balanceOf = await Contract.methods.balanceOf(address).call();
      var decimals = await Contract.methods.decimals().call();
      var bal = await divideDecimal(balanceOf, decimals);
      bal = await convert(bal);

      return {
        balance: bal,
        balanceOf: balanceOf,
        error: "",
      };
    }
  } catch (err) {
    return {
      balance: 0,
      balanceOf: 0,
      error: "",
    };
  }
}
