import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";

import Web3 from "web3";
import moment from "moment";
import { Multicall } from "ethereum-multicall";
import copy from "copy-to-clipboard";
import DefiqStakeABI from "../ABI/StakeContract.json";
import PuffLoader from "react-spinners/PuffLoader";
import BEP20ABI from "../ABI/USDTCOntract.json";
import DefiqTokenABI from "../ABI/DefiQContract.json";
import config from "../config/config.js";
import { toastAlert } from "../helper/toastAlert.js";
import { connection } from "../helper/connection.js";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import {
  getPoolUsers,
  getROICliamHis,
  getUnStakeClaimHis,
  checkValidEntry,
  checkValidEntry2,
  get_withdraw_hist,
  getCliamedDefiQ,
  CliamDirectReward,
  CliamLevelReward,
  CliamReferalReward,
  CliamStakeAmount,
  getPoolDetails
} from "../Action/apiAction.js";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Mystaking() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState({});
  const [stakingStatus, setStakingStaus] = useState(1);
  const [stakingData, setStakingData] = useState([]);
  const [roiHis, setRoiHis] = useState([]);
  const [unStakeHis, setUnStakeHis] = useState([]);
  const [allHis, setAllHis] = useState([]);
  const [DirectwithdrawHist, setdirectwithdrawHist] = useState([]);
  const [ReferralwithdrawHist, setReferralwithdrawHist] = useState([]);
  const [ClaimedAmount, setClaimedAmount] = useState(0);
  const [levWisC, setLevWisC] = useState([]);
  const [levWisV, setLevWisV] = useState([]);
  const [refVolumns, setRefVolumns] = useState([]);

  const levelUnstake = {
    1: 20,
    2: 20,
    3: 20,
    4: 20,
    5: 20
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.SSNo
    },
    {
      name: "Date & Time",
      selector: (row) => moment(row.createdAt).format("lll")
    },

    {
      name: "Token",
      selector: (row) => "DefiQ"
    },
    {
      name: "Amount",
      selector: (row) => Number(row.rewardAmount).toFixed(4)
    },
    {
      name: "Plan ID",
      selector: (row) => row.userPoolId
    },
    {
      name: "Status",
      selector: (row) => "Success"
    },
    {
      name: "Hash",
      selector: (row) => (
        <a target="_blank" href={config.txurl + row.txHash}>
          {halfAddrShow(row.txHash)}
        </a>
      )
    }
  ];

  const columns1 = [
    {
      name: "S.No",
      selector: (row) => row.SSNo
    },
    {
      name: "Date & Time",
      selector: (row) => moment(row.createdAt).format("lll")
    },

    {
      name: "Token",
      selector: (row) => "DefiQ"
    },
    {
      name: "Amount",
      selector: (row) => Number(row.totalAmount).toFixed(4)
    },
    {
      name: "Plan ID",
      selector: (row) => row.userPoolId
    },
    {
      name: "Status",
      selector: (row) => "Success"
    },
    {
      name: "Hash",
      selector: (row) => (
        <a target="_blank" href={config.txurl + row.txHash}>
          {halfAddrShow(row.txHash)}
        </a>
      )
    }
  ];

  const columns3 = [
    {
      name: "S.No",
      selector: (row) => row.SSNo
    },
    {
      name: "Date & Time",
      selector: (row) => moment(row?.createdAt).format("lll")
    },
    {
      name: "Amount",
      selector: (row) => row.claimAmount
    },
    {
      name: "Hash",
      selector: (row) => (
        <a target="_blank" href={config.txurl + row.txHash}>
          {halfAddrShow(row.txHash)}
        </a>
      )
    }
  ];

  useEffect(() => {
    getwithdrawhist();
    getStakedAmount();
  }, [walletConnection.address]);

  const getStakedAmount = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address
      };
      const data = await getCliamedDefiQ(payload);
      console.log(data, "datadatadata");
      setClaimedAmount(data?.result?.result);
    }
  };

  const getwithdrawhist = async () => {
    if (walletConnection.address) {
      let { status, directHist, referralHist } = await get_withdraw_hist({
        address: walletConnection.userDetails?.id
      });
      directHist?.map((ele, i) => {
        ele.SSNo = i + 1;
      });
      referralHist?.map((ele, i) => {
        ele.SSNo = i + 1;
      });
      if (status) {
        setdirectwithdrawHist(directHist);
        setReferralwithdrawHist(referralHist);
      }
    }
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 7) + "...." + addr.slice(addr.length - 4);
    } else {
      return "";
    }
  };
  const halfAddrShow1 = (addr) => {
    if (addr) {
      return addr.substring(0, 8) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  async function copyToClipboard() {
    if (walletConnection.address) {
      copy(config.Front_URL + "staking/" + walletConnection.address);
      toastAlert("success", "Copied Successfully");
    } else {
      toastAlert("error", "Please connect your wallet");
    }
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
    ) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getUserDetails();
    userChecking();
    getRoiHis();
    LevelWiseCount();
    LevelWiseVolumn();
  }, [walletConnection.address]);

  useEffect(() => {
    getUsersPoolDetails();
  }, [walletConnection.address, stakingStatus]);

  async function LevelWiseCount() {
    try {
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.address != undefined &&
        walletConnection.address != null &&
        walletConnection.address != ""
      ) {
        // walletConnection.address = "0xBc5e9712513ebEbEB00ebb3AbEBAc2950af0c0b6";
        let levCounts = [];
        let refVolums = [];
        let web3 = walletConnection.web3;
        var DefiqStakeCont = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );
        for (let i = 0; i < 10; i++) {
          let levWiseCnt = await DefiqStakeCont.methods
            .levelWiseCount(walletConnection.address, i.toString())
            .call();
          levCounts.push({ level: i + 1, value: levWiseCnt });
        }
        console.log(levCounts, "levCounts");
        setLevWisC(levCounts);

        let userRefs = await DefiqStakeCont.methods
          .viewUserReferal(walletConnection.address)
          .call();
        console.log(userRefs, "userRefs");
        for (let j = 0; j < userRefs.length; j++) {
          let refVol = await DefiqStakeCont.methods
            .teamVolume(userRefs[j])
            .call();

          refVolums.push({
            referals: userRefs[j],
            volumn: Number(refVol) / 1e18
          });
        }
        console.log(refVolums, "refVolums");
        setRefVolumns(refVolums);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function LevelWiseVolumn() {
    try {
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.address != undefined &&
        walletConnection.address != null &&
        walletConnection.address != ""
      ) {
        // walletConnection.address = "0xBc5e9712513ebEbEB00ebb3AbEBAc2950af0c0b6";
        let levCounts = [];
        let refVolums = [];
        let web3 = walletConnection.web3;
        var DefiqStakeCont = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );
        for (let i = 0; i < 10; i++) {
          let levWiseCnt = await DefiqStakeCont.methods
            .levelWiseVolume(walletConnection.address, i.toString())
            .call();
          levCounts.push({ level: i + 1, value: levWiseCnt });
        }
        console.log(levCounts, "levCounts");
        setLevWisV(levCounts);

        // let userRefs = await DefiqStakeCont.methods
        //   .viewUserReferal(walletConnection.address)
        //   .call();
        // console.log(userRefs, "userRefs");
        // for (let j = 0; j < userRefs.length; j++) {
        //   let refVol = await DefiqStakeCont.methods
        //     .teamVolume(userRefs[j])
        //     .call();

        //   refVolums.push({
        //     referals: userRefs[j],
        //     volumn: Number(refVol) / 1e18
        //   });
        // }
        // console.log(refVolums, "refVolums");
        // setRefVolumns(refVolums);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function userChecking() {
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
      // walletConnection?.userDetails
    ) {
      let web3 = walletConnection.web3;
      var DefiqStakeCont = new web3.eth.Contract(
        DefiqStakeABI,
        config.DefiqStakeAddrs
      );
      let userDetails = await DefiqStakeCont.methods
        .users(walletConnection.address)
        .call();
      console.log(userDetails, "userDetailsuserDetails");
      if (!userDetails.isExist) {
        window.location.href = "/staking";
      }
      // if (
      //   walletConnection.userDetails &&
      //   !walletConnection?.userDetails.isExist
      // ) {
      //   window.location.href = "/staking";
      // }
    }
  }

  const getRoiHis = async () => {
    if (walletConnection.address) {
      const payload = {
        userID: walletConnection?.userDetails?.id
      };
      const data = await getROICliamHis(payload);
      const data1 = await getUnStakeClaimHis(payload);
      data?.data.map((ele, i) => {
        ele.SSNo = i + 1;
      });
      data1?.data.map((ele, i) => {
        ele.SSNo = i + 1;
      });
      setRoiHis(data?.data);
      setUnStakeHis(data1?.data);
      let newArray = [...data?.data, ...data1?.data];
      setAllHis(newArray);
    }
  };

  useEffect(() => {
    getUsersPoolDetailsContract();
  }, [walletConnection.address]);

  const getUsersPoolDetailsContract = async () => {
    var get = await connection();
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      console.log(get.address, "get.address");
      let Contract = new web3.eth.Contract(
        DefiqStakeABI,
        config.DefiqStakeAddrs
      );
      let UpLArr = [];
      const data = await getPoolDetails();
      for (let i = 0; i < data?.data?.length; i++) {
        let pool = await Contract.methods.poolInfo(i.toString()).call();
        let upl = await Contract.methods
          .uesrPoolLenght(address, i.toString())
          .call();
        if (upl > 0) {
          for (let j = 1; j <= upl; j++) {
            console.log(j, "uplupl");
            let up = await Contract.methods
              .userPoolInfo(address, i.toString(), j.toString())
              .call();
            console.log(up, "up55555");
            up.poolDetails = pool;
            up.poolid = i;
            up.poolLenght = j;
            up.RewardAmountClaimed = Number(up.RewardAmountClaimed);
            up.stakeAmountClaimed = Number(up.stakeAmountClaimed);
            up.joinedDate = Number(up.joinedDate);
            up.lastCliameddate = Number(up.lastCliameddate);
            up.poolDetails.monthlyReward = Number(up.poolDetails.monthlyReward);
            up.poolDetails.poolAmount = Number(up.poolDetails?.poolAmount);
            up.poolDetails.poolUsdtAmount = Number(
              up.poolDetails?.poolUsdtAmount
            );
            UpLArr.push(up);
          }
        }
      }
      console.log(UpLArr, "UpLArr");
      if (UpLArr.length > 0) {
        const details = await Promise.all(
          UpLArr.map(async (elem, i) => {
            console.log(elem, "elem", elem.joinedDate);
            const createdAtDate = new Date(elem.joinedDate * 1000);
            const currentDate = new Date();
            const difference = currentDate - createdAtDate;
            console.log(difference, "differencee", createdAtDate, currentDate);
            const millisecondsInADay = 1000 * 60 * 60 * 24;
            const differenceInDays = Math.floor(
              difference / millisecondsInADay
            );
            console.log(differenceInDays, "differenceInDays");
            var web3 = walletConnection.web3;
            var address = walletConnection.address;
            let Contract = new web3.eth.Contract(
              DefiqStakeABI,
              config.DefiqStakeAddrs
            );
            let usersPools = await Contract.methods
              .userPoolInfo(address, elem.poolid, elem.poolLenght)
              .call();
            let stake = true;
            let unstake = false;
            let complete = false;
            let joinDate = new Date(
              Number(usersPools.joinedDate) * 1000
            ).getTime();
            let currDate = new Date().getTime();
            const timeDifference = currDate - joinDate;
            const millisecondsInYear = 365 * 24 * 60 * 60 * 1000;
            const years = Math.floor(timeDifference / millisecondsInYear);
            console.log(years, "yearsyearsyearsyears");

            if (
              years > 0 &&
              Number(elem.RewardAmountClaimed) <
                ((Number(elem?.poolDetails?.poolAmount) *
                  Number(elem?.poolDetails?.monthlyReward)) /
                  100) *
                  12 &&
              Number(elem.stakeAmountClaimed) <
                Number(elem.poolDetails?.poolAmount)
            ) {
              unstake = true;
              stake = true;
            } else if (
              years > 0 &&
              Number(elem.RewardAmountClaimed) <
                ((Number(elem?.poolDetails?.poolAmount) *
                  Number(elem?.poolDetails?.monthlyReward)) /
                  100) *
                  12 &&
              Number(elem.stakeAmountClaimed) >=
                Number(elem.poolDetails?.poolAmount)
            ) {
              stake = true;
              unstake = false;
            } else if (
              years > 0 &&
              Number(elem.RewardAmountClaimed) >=
                ((Number(elem?.poolDetails?.poolAmount) *
                  Number(elem?.poolDetails?.monthlyReward)) /
                  100) *
                  12 &&
              Number(elem.stakeAmountClaimed) <
                Number(elem.poolDetails?.poolAmount)
            ) {
              unstake = true;
              stake = false;
            } else if (
              Number(elem.stakeAmountClaimed) >=
                Number(elem.poolDetails?.poolAmount) &&
              Number(elem.RewardAmountClaimed) >=
                ((Number(elem?.poolDetails?.poolAmount) *
                  Number(elem?.poolDetails?.monthlyReward)) /
                  100) *
                  12
            ) {
              stake = false;
              unstake = false;
              complete = true;
            }
            // if()
            return {
              ...elem,
              differenceInDays: differenceInDays,
              unstake: unstake,
              stake: stake,
              complete: complete
            };
          })
        );
        // if (walletConnection.address !== config.AdminAddress) {
        setStakingData(details);
        // }
      }
    }
  };

  const getUsersPoolDetails = async () => {
    if (walletConnection.address) {
      console.log(walletConnection, "walletConnection");
      const payload = {
        address: walletConnection.address,
        userID: walletConnection?.userDetails?.id
        // status: stakingStatus
      };
      const getData = await getPoolUsers(payload);
      console.log(getData, "getData");
      const details = await Promise.all(
        getData?.data.map(async (elem, i) => {
          const createdAtDate = new Date(elem.joinedDate);
          const currentDate = new Date();
          const difference = currentDate - createdAtDate;
          console.log(currentDate, "weeweweweweewew", createdAtDate);
          const millisecondsInADay = 1000 * 60 * 60 * 24;
          const differenceInDays = Math.floor(difference / millisecondsInADay);
          console.log(differenceInDays, "differenceInDays11");
          var web3 = walletConnection.web3;
          var address = walletConnection.address;
          let Contract = new web3.eth.Contract(
            DefiqStakeABI,
            config.DefiqStakeAddrs
          );
          let usersPools = await Contract.methods
            .userPoolInfo(address, elem.poolid, elem.poolLenght)
            .call();
          let stake = true;
          let unstake = false;
          let complete = false;
          let joinDate = new Date(
            Number(usersPools.joinedDate) * 1000
          ).getTime();
          let currDate = new Date().getTime();
          const timeDifference = currDate - joinDate;
          const millisecondsInYear = 365 * 24 * 60 * 60 * 1000;
          const years = Math.floor(timeDifference / millisecondsInYear);
          console.log(years, "yearsyearsyearsyears");
          {
            /* {((elem.poolAmount *elem.monthlyReward) /100) *12} */
          }
          if (
            years > 0 &&
            elem.RewardAmountClaimed <
              ((elem?.poolDetails?.poolAmount *
                elem?.poolDetails?.monthlyReward) /
                100) *
                12 &&
            elem.stakeAmountClaimed < elem.poolDetails?.poolAmount
          ) {
            unstake = true;
            stake = true;
          } else if (
            years > 0 &&
            elem.RewardAmountClaimed <
              ((elem?.poolDetails?.poolAmount *
                elem?.poolDetails?.monthlyReward) /
                100) *
                12 &&
            elem.stakeAmountClaimed >= elem.poolDetails?.poolAmount
          ) {
            stake = true;
            unstake = false;
          } else if (
            years > 0 &&
            elem.RewardAmountClaimed >=
              ((elem?.poolDetails?.poolAmount *
                elem?.poolDetails?.monthlyReward) /
                100) *
                12 &&
            elem.stakeAmountClaimed < elem.poolDetails?.poolAmount
          ) {
            unstake = true;
            stake = false;
          } else if (
            elem.stakeAmountClaimed >= elem.poolDetails?.poolAmount &&
            elem.RewardAmountClaimed >=
              ((elem?.poolDetails?.poolAmount *
                elem?.poolDetails?.monthlyReward) /
                100) *
                12
          ) {
            stake = false;
            unstake = false;
            complete = true;
          }
          // if()
          return {
            ...elem,
            differenceInDays: differenceInDays,
            unstake: unstake,
            stake: stake,
            complete: complete
          };
        })
      );
      console.log(details, "detailsdetails");
      // if (walletConnection.address == config.AdminAddress) {
      //   setStakingData(details);
      // }
    }
  };

  const Claim_Direct_Reward = async () => {
    try {
      setLoading(true);
      var get = await connection();
      if (get && get.web3) {
        var web3 = get.web3;
        var address = get.address;
        console.log(get.address, "get.address");
        let Contract = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );

        let users = await Contract.methods.users(address).call();
        console.log(users.withdrawAmount, "users.withdrawAmount");
        let prevAmount = users.withdrawAmount;
        if (Number(users?.directIncome) / 1e18 > 0) {
          await Contract.methods
            .cliamDirectReward()
            .send({ from: address })
            .then(async (res) => {
              if (res.status == true && res.transactionHash) {
                let latestUser = await Contract.methods.users(address).call();
                const payload = {
                  _user: address,
                  claimAmount: latestUser.withdrawAmount - prevAmount,
                  hash: res.transactionHash
                };
                console.log(payload, "payload");
                const data = await CliamDirectReward(payload);
                if (data?.data?.status) {
                  toastAlert("success", "Claim successfully completed");
                  setTimeout(() => {
                    window.location.reload();
                  }, 5000);
                }
              } else {
                setLoading(false);
                toastAlert("error", "Claim failed, try again later");
                return false;
              }
            })
            .catch((err) => {
              setLoading(false);
              toastAlert("error", "Transaction Rejected");
              return false;
            });
        } else {
          setLoading(false);
          toastAlert("error", "Claim balance is low");
          return false;
        }
      } else {
        setLoading(false);
        toastAlert("error", "Connect your wallet");
        return false;
      }
    } catch (err) {
      console.log("errrr: ", err);
      setLoading(false);
    }
  };

  const cliam_Referal_Reward = async () => {
    try {
      setLoading(true);
      var get = await connection();
      if (get && get.web3) {
        var web3 = get.web3;
        var address = get.address;
        let Contract = new web3.eth.Contract(
          DefiqStakeABI,
          config.DefiqStakeAddrs
        );

        let users = await Contract.methods.users(address).call();
        let prevAmount = users.withdrawAmount;
        if (Number(users?.levelRewards) / 1e18 > 0) {
          await Contract.methods
            .cliamReferalReward()
            .send({ from: address })
            .then(async (res) => {
              if (res.status == true && res.transactionHash) {
                let latestUser = await Contract.methods.users(address).call();
                const payload = {
                  _user: address,
                  claimAmount: latestUser.withdrawAmount - prevAmount,
                  hash: res.transactionHash
                };
                console.log(payload, "payload");
                const data = await CliamReferalReward(payload);
                if (data?.data?.status) {
                  toastAlert("success", "Claim successfully completed");
                  setTimeout(() => {
                    window.location.reload();
                  }, 5000);
                }
              } else {
                setLoading(false);
                toastAlert("error", "Claim failed, try again later");
                return false;
              }
            })
            .catch((err) => {
              setLoading(false);
              toastAlert("error", "Transaction Rejected");
              return false;
            });
        } else {
          setLoading(false);
          toastAlert("error", "Claim balance is low");
          return false;
        }
      } else {
        setLoading(false);
        toastAlert("error", "Connect your wallet");
        return false;
      }
    } catch (err) {
      console.log("errrr: ", err);
      setLoading(false);
    }
  };

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  const getUserDetails = async () => {
    try {
      var get = await connection();
      if (get && get.web3) {
        let address = get.address;
        const multicall = new Multicall({
          web3Instance: get.web3
        });

        let contractData = [
          {
            reference: "details",
            contractAddress: config.DefiqStakeAddrs,
            abi: DefiqStakeABI,
            calls: [
              {
                reference: "userprofile",
                methodName: "users",
                methodParameters: [address]
              }
            ]
          }
        ];
        const results = await multicall.call(contractData);
        let userprofile = await getFormatMulticall(results, "userprofile", 0);

        if (userprofile[0]) {
          let userexist = true;
          let userID = await bignumber(userprofile[1]);
          let DirectReferralIncome = await bignumber(userprofile[2]);
          let monthlyRewards = await bignumber(userprofile[3]);
          let levelRewards = await bignumber(userprofile[4]);
          let refererAddress = userprofile[5];
          let referrerID = await bignumber(userprofile[6]);
          let withdrawAmount = await bignumber(userprofile[7]);
          let totalInvestment = await bignumber(userprofile[8]);
          let stakeAmountClaimed = await bignumber(userprofile[9]);

          let UserData = {
            userexist: userexist,
            userID: userID,
            DirectReferralIncome: DirectReferralIncome,
            monthlyRewards: monthlyRewards,
            levelRewards: levelRewards,
            refererAddress: refererAddress,
            referrerID: referrerID,
            withdrawAmount: withdrawAmount,
            totalInvestment: totalInvestment,
            stakeAmountClaimed: stakeAmountClaimed
          };
          setUserData(UserData);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++==");
      return "";
    }
  }

  const handleClaimReward = async (cliamId, poolId) => {
    if (walletConnection.address) {
      try {
        console.log(cliamId, poolId, "CheckIds");
        setLoading(true);
        var get = await connection();
        if (get && get.web3) {
          var web3 = get.web3;
          var address = get.address;
          let Contract = new web3.eth.Contract(
            DefiqStakeABI,
            config.DefiqStakeAddrs
          );
          let userPool = await Contract.methods
            .userPoolInfo(address, poolId.toString(), cliamId.toString())
            .call();

          let timestamp2 = new Date(Number(userPool.lastCliameddate) * 1000);
          let timestamp1 = new Date(Number(userPool.joinedDate) * 1000);
          if (timestamp1 > timestamp2) {
            setLoading(false);
            toastAlert("error", "Invalid Times");
            return false;
          }
          const millisecondsInDay = 1000 * 60 * 60 * 24;
          const millisecondsIn365Days = millisecondsInDay * 365;
          const millisecondsIn30Days = millisecondsInDay * 30;
          let timeDiff = timestamp2 - timestamp1;
          let yearsDiff = timeDiff / millisecondsIn365Days;
          console.log(Math.floor(yearsDiff), "yearsDiff");
          if (Math.floor(yearsDiff) > 0) {
            setLoading(false);
            toastAlert("error", "Already Claimed Full Amount");
            return false;
          }
          timeDiff = new Date() - timestamp2;
          let monthsDiff = Math.floor(timeDiff / millisecondsIn30Days);
          console.log(monthsDiff, "monthsDiff1");
          if (monthsDiff <= 0) {
            setLoading(false);
            toastAlert("error", "Already Claimed for this month Amount");
            return false;
          }

          if (Number(userPool.ClaimCount) + monthsDiff > 12) {
            monthsDiff = 12 - Number(userPool.ClaimCount);
          }
          console.log(monthsDiff, "monthsDiff2");
          if (monthsDiff <= 0) {
            setLoading(false);
            toastAlert("error", "Something Wrong");
            return false;
          }

          // const payload = {
          //   address: address,
          //   cliamId,
          //   poolId
          // };
          // const CheckValid = await checkValidEntry(payload);
          // console.log(CheckValid, "CheckValid");
          // if (CheckValid?.data?.status == false) {
          //   setLoading(false);
          //   toastAlert("error", CheckValid?.data?.message);
          //   return false;
          // }
          let users = await Contract.methods.users(address).call();
          await Contract.methods
            .cliamLevelReward(cliamId.toString(), poolId.toString())
            .send({ from: address })
            .then(async (res) => {
              if (res.status == true && res.transactionHash) {
                let latestUser = await Contract.methods.users(address).call();
                const payload = {
                  _cliamId: cliamId,
                  _pid: poolId,
                  rewardAmount:
                    latestUser.withdrawAmount - users.withdrawAmount,
                  user: address,
                  hash: res.transactionHash
                };
                const data = await CliamLevelReward(payload);
                if (data?.data?.status) {
                  toastAlert("success", "Claim successfully completed");
                  setTimeout(() => {
                    window.location.reload();
                  }, 5000);
                }
              } else {
                setLoading(false);
                toastAlert("error", "Claim failed, try again later");
                return false;
              }
            })
            .catch((err) => {
              setLoading(false);
              toastAlert("error", "Transaction Rejected");
              return false;
            });
        } else {
          setLoading(false);
          toastAlert("error", "Connect your wallet");
          return false;
        }
      } catch (err) {
        console.log("errrr: ", err);
        setLoading(false);
      }
    }
  };

  const handleStakeReward = async (cliamId, poolId) => {
    if (walletConnection.address) {
      try {
        console.log(cliamId, poolId, "CheckIds");
        setLoading(true);
        var get = await connection();
        if (get && get.web3) {
          var web3 = get.web3;
          var address = get.address;
          let Contract = new web3.eth.Contract(
            DefiqStakeABI,
            config.DefiqStakeAddrs
          );
          let userPool = await Contract.methods
            .userPoolInfo(address, poolId.toString(), cliamId.toString())
            .call();
          let poolInfo = await Contract.methods
            .poolInfo(poolId.toString())
            .call();

          let timestamp1 = new Date(
            Number(userPool.joinedDate) * 1000
          ).getTime();
          let timeDiff = new Date() - timestamp1;
          const millisecondsInDay = 1000 * 60 * 60 * 24;
          const millisecondsIn365Days = millisecondsInDay * 365;
          const millisecondsIn30Days = millisecondsInDay * 30;
          let yearsDiff = timeDiff / millisecondsIn365Days;
          console.log(yearsDiff, "yearsDiff");
          if (yearsDiff <= 0) {
            setLoading(false);
            toastAlert("error", "Staking Period not completed");
            return false;
          }
          if (
            Number(poolInfo.poolAmount) / 1e18 <=
            Number(userPool.stakeAmountClaimed) / 1e18
          ) {
            setLoading(false);
            toastAlert("error", "Already Cliamed");
            return false;
          }
          let startTimestamp = timestamp1 + millisecondsIn365Days;
          console.log(startTimestamp, "startTimestamp");
          timeDiff = new Date() - startTimestamp;
          console.log(timeDiff, "timeDiff");
          let monthsDiff = Math.floor(timeDiff / millisecondsIn30Days);
          console.log(monthsDiff, "monthsDiff");
          let totalPercentage = 0;
          for (let i = 1; i <= monthsDiff; i++) {
            totalPercentage += levelUnstake[i];
          }
          if (totalPercentage > 100) {
            totalPercentage = 100;
          }
          console.log(totalPercentage, "totalPercentage");
          let totalAmount =
            ((poolInfo.poolAmount / 1e18) * totalPercentage) / 100 -
            userPool.stakeAmountClaimed / 1e18;
          console.log(totalAmount, "totalAmount");
          if (totalAmount <= 0) {
            setLoading(false);
            toastAlert("error", "You need to wait for this month Completion");
            return false;
          }

          // const payload = {
          //   address: address,
          //   cliamId,
          //   poolId
          // };

          // const CheckValid = await checkValidEntry2(payload);
          // console.log(CheckValid, "CheckValid");
          // if (CheckValid?.data?.status == false) {
          //   setLoading(false);
          //   toastAlert("error", CheckValid?.data?.message);
          //   return false;
          // }
          let users = await Contract.methods.users(address).call();
          let prevAmount = users.stakeAmountClaimed;
          await Contract.methods
            .cliamStakeAmount(cliamId, poolId)
            .send({ from: address })
            .then(async (res) => {
              if (res.status == true && res.transactionHash) {
                let latestUsers = await Contract.methods.users(address).call();
                const payload = {
                  _cliamId: cliamId,
                  _pid: poolId,
                  totalAmount: latestUsers.stakeAmountClaimed - prevAmount,
                  user: address,
                  hash: res.transactionHash
                };
                const data = await CliamStakeAmount(payload);
                if (data?.data?.status) {
                  toastAlert("success", "Claim successfully completed");
                  setTimeout(() => {
                    window.location.reload();
                  }, 5000);
                }
              } else {
                setLoading(false);
                toastAlert("error", "Claim failed, try again later");
                return false;
              }
            })
            .catch((err) => {
              setLoading(false);
              toastAlert("error", "Catch error, try again later");
              return false;
            });
        } else {
          setLoading(false);
          toastAlert("error", "Connect your wallet");
          return false;
        }
      } catch (err) {
        console.log("errrr: ", err);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="page_box pb-5">
        <div className="container">
          <div className="head_box mb-5">
            <h3>My Staking</h3>
          </div>
          <div className="detail_box mb-5">
            <div className="box">
              <img
                src={require("../assets/images/m1.png")}
                class="img-fluid"
                alt="img"
              />
              <div>
                <p>Total Locked Value</p>
                <p className="mb-0">
                  {UserData?.totalInvestment > 0
                    ? (
                        UserData?.totalInvestment / 1e18 -
                        ClaimedAmount
                      ).toFixed(4)
                    : 0}{" "}
                  <span> DeFiQ</span>
                </p>
              </div>
            </div>
            <div className="box">
              <img
                src={require("../assets/images/m2.png")}
                class="img-fluid"
                alt="img"
              />
              <div className="d-flex align-items-center justify-content-between w-100">
                <div>
                  <p>Direct Income</p>
                  <p className="mb-0">
                    {UserData?.DirectReferralIncome
                      ? (UserData?.DirectReferralIncome / 1e18).toFixed(4)
                      : 0}{" "}
                    <span> USD</span>
                  </p>
                </div>
                <button
                  className="claim bg-transparent"
                  onClick={Claim_Direct_Reward}
                  disabled={
                    UserData?.DirectReferralIncome / 1e18 == 0 ? true : false
                  }
                >
                  Claim
                </button>
              </div>
            </div>
            <div className="box">
              <img
                src={require("../assets/images/m3.png")}
                class="img-fluid"
                alt="img"
              />
              <div className="d-flex align-items-center justify-content-between w-100">
                <div>
                  <p> Level Income</p>
                  <p className="mb-0">
                    {UserData?.levelRewards
                      ? (UserData?.levelRewards / 1e18).toFixed(4)
                      : 0}{" "}
                    <span> USD</span>
                  </p>
                </div>
                <button
                  className="claim"
                  onClick={cliam_Referal_Reward}
                  disabled={UserData?.levelRewards / 1e18 == 0 ? true : false}
                >
                  Claim
                </button>
              </div>
            </div>
            <div className="box affiliate">
              <div className="d-flex align-items-center gap-2">
                {/* <img src={link} class="img-fluid" alt="img" />  */}
                <span>Affiliate Link</span>
              </div>
              <div className="link">
                <p className="mb-0">
                  {config.Front_URL +
                    "staking/" +
                    halfAddrShow(walletConnection.address)}
                </p>
                <i
                  class="fa-regular fa-copy cursor"
                  onClick={copyToClipboard}
                ></i>
                {/* <img src={copy} class="img-fluid cursor" alt="img" />  */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="table_box mb-3">
                <div className="head">
                  <div className="flx">
                    <div>Level</div>
                    <div>Total Count</div>
                  </div>
                </div>
                {/* <Scrollbars style={{width:"100%", height: 450 }}> */}
                <div className="body">
                  {levWisC.length > 0 &&
                    levWisC.map((ele) => {
                      return (
                        <div className="flx">
                          <div>{ele.level}</div>
                          <div>{ele.value}</div>
                        </div>
                      );
                    })}

                  {/* <div className="flx">
                    <div>2</div>
                    <div>30</div>
                  </div>
                  <div className="flx">
                    <div>3</div>
                    <div>80</div>
                  </div>
                  <div className="flx">
                    <div>4</div>
                    <div>100</div>
                  </div>
                  <div className="flx">
                    <div>5</div>
                    <div>130</div>
                  </div>
                  <div className="flx">
                    <div>6</div>
                    <div>180</div>
                  </div>
                  <div className="flx">
                    <div>7</div>
                    <div>200</div>
                  </div>
                  <div className="flx">
                    <div>8</div>
                    <div>280</div>
                  </div>
                  <div className="flx">
                    <div>9</div>
                    <div>390</div>
                  </div>
                  <div className="flx border-0">
                    <div>10</div>
                    <div>400</div>
                  </div> */}
                </div>
                {/* </Scrollbars> */}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="table_box mb-3">
                <div className="head">
                  <div className="flx">
                    {/* <div>Level</div> */}
                    <div>Level Volumn</div>
                  </div>
                </div>
                {/* <Scrollbars style={{width:"100%", height: 450 }}> */}
                <div className="body">
                  {levWisV.length > 0 &&
                    levWisV.map((ele) => {
                      return (
                        <div className="flx">
                          {/* <div>{ele.level}</div> */}
                          <div>{(ele.value / 1e18).toFixed(4)}</div>
                        </div>
                      );
                    })}
                </div>
                {/* </Scrollbars> */}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="table_box mb-3 scrollcolor">
                <div className="head">
                  <div className="flx">
                    <div>Address</div>
                    <div>Count</div>
                  </div>
                </div>
                <Scrollbars style={{ width: "100%", height: 450 }}>
                  <div className="body">
                    {refVolumns.length > 0 &&
                      refVolumns.map((ele) => {
                        return (
                          <div className="flx">
                            <div>{halfAddrShow1(ele.referals)}</div>
                            <div>{ele.volumn}</div>
                          </div>
                        );
                      })}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>

          <ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="one-tab"
                data-bs-toggle="tab"
                data-bs-target="#one-tab-pane"
                type="button"
                role="tab"
                aria-controls="one-tab-pane"
                aria-selected="true"
                onClick={() => setStakingStaus(1)}
              >
                Staking Details
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="two-tab"
                data-bs-toggle="tab"
                data-bs-target="#two-tab-pane"
                type="button"
                role="tab"
                aria-controls="two-tab-pane"
                aria-selected="false"
                onClick={() => setStakingStaus(2)}
              >
                Unstaking Details
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="three-tab"
                data-bs-toggle="tab"
                data-bs-target="#three-tab-pane"
                type="button"
                role="tab"
                aria-controls="three-tab-pane"
                aria-selected="false"
                onClick={() => setStakingStaus(3)}
              >
                Complete
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="one-tab-pane"
              role="tabpanel"
              aria-labelledby="one-tab"
              tabindex="0"
            >
              <div className="staking">
                <div className="row">
                  {stakingData.length > 0 &&
                    stakingData.map((elem, i) => {
                      return (
                        elem?.stake &&
                        !elem.complete && (
                          <div className="col-lg-4">
                            <div className="box mb-4">
                              <div className="inbox mb-2 font">
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={require("../assets/images/defiq.png")}
                                    class="img-fluid"
                                    alt="img"
                                  />
                                  <span>DeFiQ</span>
                                </div>
                                <p>
                                  <span className="me-1">
                                    {(
                                      elem?.poolDetails?.poolAmount / 1e18
                                    ).toFixed(2)}
                                  </span>
                                  DefiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Rewards Received</p>
                                <p>
                                  <span className="me-1">
                                    {elem.RewardAmountClaimed
                                      ? (
                                          elem.RewardAmountClaimed / 1e18
                                        ).toFixed(4)
                                      : 0}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Remaining Rewards</p>
                                <p>
                                  {/* ((elem.poolAmount *
                                          elem.monthlyReward) /
                                          100) *
                                          12 */}
                                  <span className="me-1">
                                    {(((elem?.poolDetails?.poolAmount *
                                      elem?.poolDetails?.monthlyReward) /
                                      100) *
                                      12 -
                                      elem.RewardAmountClaimed) /
                                      1e18}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-4 d-block">
                                <p className="mb-3">Day Progress</p>

                                <div class="progress">
                                  <div
                                    class="progress-bar progress-bar-striped"
                                    role="progressbar"
                                    style={{
                                      width: `${
                                        (elem?.differenceInDays / 360) * 100
                                      }%`
                                    }}
                                    aria-valuenow="10"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                  <span>
                                    {(elem?.differenceInDays / 360) * 100 > 100
                                      ? "Completed"
                                      : (
                                          (elem?.differenceInDays / 360) *
                                          100
                                        ).toFixed(2) + "%"}
                                  </span>
                                  <p>
                                    <span>
                                      {elem?.differenceInDays > 360
                                        ? 360
                                        : elem?.differenceInDays}
                                      /360
                                    </span>{" "}
                                    (Days)
                                  </p>
                                </div>
                              </div>
                              {/* Check 2nd */}
                              {/* <div className="inbox mb-4 d-block blue">
                                <p className="mb-3">Rewards Claimed</p>
                                <div class="progress">
                                  <div
                                    class="progress-bar progress-bar-striped"
                                    role="progressbar"
                                    style={{
                                      width: `${(
                                        (elem?.RewardAmountClaimed /
                                          (((elem?.poolDetails?.poolAmount *
                                            elem?.poolDetails?.monthlyReward) /
                                            100) *
                                            12)) *
                                        100
                                      ).toFixed(2)}%`
                                    }}
                                    aria-valuenow="10"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                  <span>
                                    {(
                                      (elem?.RewardAmountClaimed /
                                        (((elem?.poolDetails?.poolAmount *
                                          elem?.poolDetails?.monthlyReward) /
                                          100) *
                                          12)) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </span>
                                  <p>
                                    <span>
                                      {(elem?.RewardAmountClaimed * 12) /
                                        (((elem?.poolDetails?.poolAmount *
                                          elem?.poolDetails?.monthlyReward) /
                                          100) *
                                          12)}
                                      /12
                                    </span>{" "}
                                    (Months)
                                  </p>
                                </div>
                              </div> */}
                              {/* Check 2nd */}
                              <button
                                className="claim w-100"
                                disabled={
                                  new Date(
                                    elem?.lastCliameddate * 1000
                                  ).getTime() +
                                    30 * 24 * 60 * 60 * 1000 <
                                  Date.now()
                                    ? false
                                    : true
                                }
                                onClick={() =>
                                  handleClaimReward(
                                    elem?.poolLenght,
                                    elem?.poolid
                                  )
                                }
                              >
                                {" "}
                                Claim{" "}
                              </button>
                            </div>
                          </div>
                        )
                      );
                    })}
                  <div className="history_table mt-3">
                    <div className="flx mb-4">
                      <h4>Level Reward Claim History</h4>
                      <div className="d-flex align-items-center gap-1">
                        <img
                          src={require("../assets/images/history.png")}
                          class="img-fluid"
                          alt="img"
                        />
                        <p>Recent Activity </p>
                      </div>
                    </div>
                    <DataTable
                      pagination
                      columns={columns}
                      data={roiHis}
                      responsive
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="two-tab-pane"
              role="tabpanel"
              aria-labelledby="two-tab"
              tabindex="0"
            >
              <div className="staking">
                <div className="row">
                  {stakingData.length > 0 &&
                    stakingData.map((elem, i) => {
                      return (
                        elem?.unstake &&
                        !elem.complete && (
                          <div className="col-lg-4">
                            <div className="box mb-4">
                              <div className="inbox mb-2 font">
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={require("../assets/images/defiq.png")}
                                    class="img-fluid"
                                    alt="img"
                                  />
                                  <span>DeFiQ</span>
                                </div>
                                <p>
                                  <span className="me-1">
                                    {elem?.poolDetails?.poolAmount / 1e18}
                                  </span>
                                  DefiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Unstaking Amount</p>
                                <p>
                                  <span className="me-1">
                                    {elem?.stakeAmountClaimed / 1e18}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Balance Amount</p>
                                <p>
                                  <span className="me-1">
                                    {(elem?.poolDetails?.poolAmount -
                                      elem?.stakeAmountClaimed) /
                                      1e18}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Reward Received</p>
                                <p>
                                  <span className="me-1">
                                    {elem?.RewardAmountClaimed
                                      ? (
                                          elem?.RewardAmountClaimed / 1e18
                                        ).toFixed(4)
                                      : 0}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-4 d-block blue">
                                <p className="mb-3">Unstake Progress</p>
                                <div class="progress">
                                  <div
                                    class="progress-bar progress-bar-striped"
                                    role="progressbar"
                                    style={{
                                      width: `${
                                        (elem?.stakeAmountClaimed /
                                          1e18 /
                                          (elem?.poolDetails?.poolAmount /
                                            1e18)) *
                                        100
                                      }%`
                                    }}
                                    aria-valuenow="10"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                  <span>
                                    {(elem?.stakeAmountClaimed /
                                      1e18 /
                                      (elem?.poolDetails?.poolAmount / 1e18)) *
                                      100}
                                    %
                                  </span>
                                  <p>
                                    <span>
                                      {((elem?.stakeAmountClaimed / 1e18) * 5) /
                                        (elem?.poolDetails?.poolAmount / 1e18)}
                                      /5
                                    </span>{" "}
                                    (Months)
                                  </p>
                                </div>
                              </div>
                              <button
                                className="claim w-100"
                                // disabled={
                                //   elem?.stakeAmountClaimed > 0
                                //     ? new Date(
                                //         elem?.lastUnStakeddate * 1000
                                //       ).getTime() +
                                //       30 * 24 * 60 * 60 * 1000
                                //     : new Date(
                                //         elem?.joinedDate * 1000
                                //       ).getTime() +
                                //         390 * 24 * 60 * 60 * 1000 <
                                //       Date.now()
                                //     ? false
                                //     : true
                                // }
                                onClick={() =>
                                  handleStakeReward(
                                    elem?.poolLenght,
                                    elem?.poolid
                                  )
                                }
                              >
                                {" "}
                                Unstake{" "}
                              </button>
                            </div>
                          </div>
                        )
                      );
                    })}

                  <div className="history_table mt-3">
                    <div className="flx mb-4">
                      <h4>Unstake Claim History </h4>
                      <div className="d-flex align-items-center gap-1">
                        <img
                          src={require("../assets/images/history.png")}
                          class="img-fluid"
                          alt="img"
                        />
                        <p>Recent Activity</p>
                      </div>
                    </div>
                    <DataTable
                      pagination
                      columns={columns1}
                      data={unStakeHis}
                      responsive
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="three-tab-pane"
              role="tabpanel"
              aria-labelledby="three-tab"
              tabindex="0"
            >
              <div className="staking">
                <div className="row">
                  {stakingData.length > 0 &&
                    stakingData.map((elem, i) => {
                      return (
                        elem.complete && (
                          <div className="col-lg-4">
                            <div className="box mb-4">
                              <div className="inbox mb-2 font">
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={require("../assets/images/defiq.png")}
                                    class="img-fluid"
                                    alt="img"
                                  />
                                  <span>DeFiQ</span>
                                </div>
                                <p>
                                  <span className="me-1">
                                    {elem?.poolDetails?.poolAmount / 1e18}
                                  </span>
                                  DefiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Staking Amount</p>
                                <p>
                                  <span className="me-1">
                                    {" "}
                                    {elem?.stakeAmountClaimed / 1e18}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-2">
                                <p>Reward Amount</p>
                                <p>
                                  <span className="me-1">
                                    {" "}
                                    {elem?.RewardAmountClaimed / 1e18}
                                  </span>
                                  DeFiQ
                                </p>
                              </div>
                              <div className="inbox mb-4 d-block blue">
                                <p className="mb-3">Remaining Rewards</p>
                                <div class="progress">
                                  <div
                                    class="progress-bar progress-bar-striped"
                                    role="progressbar"
                                    style={{ width: "100%" }}
                                    aria-valuenow="10"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                  <span>100%</span>
                                  <p>
                                    <span>12/12</span> (Months)
                                  </p>
                                </div>
                              </div>
                              {/* <button className="claim w-100 hover"> Unstak </button> */}
                            </div>
                          </div>
                        )
                      );
                    })}
                  {/* 
                  <div className="history_table mt-3">
                    <div className="flx mb-4">
                      <h4>Recent Activity</h4>
                      <div className="d-flex align-items-center gap-1">
                        <img
                          src={require("../assets/images/history.png")}
                          class="img-fluid"
                          alt="img"
                        />
                        <p>Complete History</p>
                      </div>
                    </div>
                    <DataTable
                      pagination
                      columns={columns1}
                      data={allHis}
                      responsive
                    />
                  </div> */}

                  {/* <div className="col-lg-4">
                    <div className="box mb-4">
                      <div className="inbox mb-2 font">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={require("../assets/images/defiq.png")}
                            class="img-fluid"
                            alt="img"
                          />
                          <span>DeFiQ</span>
                        </div>
                        <p>
                          <span className="me-1">1234.12</span>USD
                        </p>
                      </div>
                      <div className="inbox mb-2">
                        <p>Staking Amount</p>
                        <p>
                          <span className="me-1">123.45</span>DeFiQ
                        </p>
                      </div>
                      <div className="inbox mb-2">
                        <p>Reward Amount</p>
                        <p>
                          <span className="me-1">123.45</span>DeFiQ
                        </p>
                      </div>
                      <div className="inbox mb-4 d-block blue">
                        <p className="mb-3">Remaining Rewards</p>
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="10"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <span>100%</span>
                          <p>
                            <span>12/12</span> (Months)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box mb-4">
                      <div className="inbox mb-2 font">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={require("../assets/images/defiq.png")}
                            class="img-fluid"
                            alt="img"
                          />
                          <span>DeFiQ</span>
                        </div>
                        <p>
                          <span className="me-1">1234.12</span>USD
                        </p>
                      </div>
                      <div className="inbox mb-2">
                        <p>Staking Amount</p>
                        <p>
                          <span className="me-1">123.45</span>DeFiQ
                        </p>
                      </div>
                      <div className="inbox mb-2">
                        <p>Reward Amount</p>
                        <p>
                          <span className="me-1">123.45</span>DeFiQ
                        </p>
                      </div>
                      <div className="inbox mb-4 d-block blue">
                        <p className="mb-3">Remaining Rewards</p>
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="10"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <span>100%</span>
                          <p>
                            <span>12/12</span> (Months)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="history_table mt-3">
            <div className="flx mb-4">
              <h4>Direct Income Withdraw History</h4>
              <div className="d-flex align-items-center gap-1">
                <img
                  src={require("../assets/images/history.png")}
                  class="img-fluid"
                  alt="img"
                />
                <p>Recent Activity </p>
              </div>
            </div>
            <DataTable
              pagination
              columns={columns3}
              data={DirectwithdrawHist}
              responsive
            />
          </div>
          <div className="history_table mt-3">
            <div className="flx mb-4">
              <h4>Referral Income Withdraw History</h4>
              <div className="d-flex align-items-center gap-1">
                <img
                  src={require("../assets/images/history.png")}
                  class="img-fluid"
                  alt="img"
                />
                <p>Recent Activity </p>
              </div>
            </div>
            <DataTable
              pagination
              columns={columns3}
              data={ReferralwithdrawHist}
              responsive
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loadBefore">
          <PuffLoader
            color={"#9CFF45ED"}
            loading={loading}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
