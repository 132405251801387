let NODE_ENV = "production";

let Front_URL = "";
let baseUrl = "";
let BUSDAddress = "";
let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let USDTAddress = "";
let DefiQToken = "";
let BnbRpcUrl = "";
let decimalValue = "";
let DefiqStakeAddrs = "";

if (NODE_ENV === "production") {
  console.log(
    "production",
    "Admin 0x4b72bC56317ec99a60F57096Fc12b737C1249f5F",
    "USDT 0x55d398326f99059fF775485246999027B3197955",
    "DefiQ 0x866E7B8F76CF2435Fde81DEA9FB4693Ba002DF8F",
    "0x6E15A39C8Ebf50E1Eab7898891D36B7C22D0087f"
  );
  Front_URL = "https://staking.defiq.live/";
  baseUrl = "https://stakingapi.defiq.live/";
  AdminAddress = "0x4b72bC56317ec99a60F57096Fc12b737C1249f5F";
  BUSDAddress = "0x6FcA582B526f510542Fa030291F2fa46CCB22478";
  USDTAddress = "0x55d398326f99059fF775485246999027B3197955"; //0x7514cA232748398761045348b8D62a9754E369AC Matic
  DefiQToken = "0x866E7B8F76CF2435Fde81DEA9FB4693Ba002DF8F"; //0x2a6aDC7A62C8f110db274B64e39ca710e9dbDf5C - Mati
  DefiqStakeAddrs = "0x6E15A39C8Ebf50E1Eab7898891D36B7C22D0087f";
  txUrlAddress = "https://bscscan.com/address/";
  txurl = "https://bscscan.com/tx/";
  BnbRpcUrl = "https://bsc.publicnode.com";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 56;
  toFixed = 8;
  toFixedBal = 18;
  decimalValue = 1e18;
  networkName = "Binance Chain";
  tokenSymbol = "DefiQ";
} else if (NODE_ENV === "demo") {
  Front_URL = "https://defiq-staking-demo.pages.dev/";
  baseUrl = "https://defiqstakingdemoapi.defiq.live/";
  AdminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  BUSDAddress = "0x6FcA582B526f510542Fa030291F2fa46CCB22478";
  USDTAddress = "0x1575De79e7FF464473e4f9C659c798a3C5593833"; //0x7514cA232748398761045348b8D62a9754E369AC Matic
  DefiQToken = "0x6FcA582B526f510542Fa030291F2fa46CCB22478"; //0x2a6aDC7A62C8f110db274B64e39ca710e9dbDf5C - Mati
  DefiqStakeAddrs = "0xcdACC5e52eFaB16B4AA8254709a6e589575B515F";
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97;
  toFixed = 8;
  toFixedBal = 18;
  decimalValue = 1e18;
  networkName = "Binance Chain";
  tokenSymbol = "DefiQ";
} else {
  Front_URL = "http://localhost:3000/";
  baseUrl = "http://localhost:3040/";
  AdminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  BUSDAddress = "0x6FcA582B526f510542Fa030291F2fa46CCB22478";
  USDTAddress = "0x1575De79e7FF464473e4f9C659c798a3C5593833"; //0x7514cA232748398761045348b8D62a9754E369AC Matic
  DefiQToken = "0x6FcA582B526f510542Fa030291F2fa46CCB22478"; //0x2a6aDC7A62C8f110db274B64e39ca710e9dbDf5C - Mati
  DefiqStakeAddrs = "0xC71F718561Fe13a263802cbD97FC67325098Ff5F";
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97;
  toFixed = 8;
  toFixedBal = 18;
  decimalValue = 1e18;
  networkName = "Binance Chain";
  tokenSymbol = "DefiQ";
}

let key = {
  Front_URL: Front_URL,
  USDTAddress: USDTAddress,
  baseUrl: baseUrl,
  BnbRpcUrl: BnbRpcUrl,
  AdminAddress: AdminAddress,
  BUSDAddress: BUSDAddress,
  DefiQToken: DefiQToken,
  BNBSYMBOL: BNBSYMBOL,
  toFixedSwap: toFixedSwap,
  toFixedBal: toFixedBal,
  toFixedvalue: toFixedvalue,
  NetworkId: NetworkId,
  toFixed: toFixed,
  networkName: networkName,
  txUrlAddress: txUrlAddress,
  txurl: txurl,
  tokenSymbol: tokenSymbol,
  decimalValue: decimalValue,
  DefiqStakeAddrs: DefiqStakeAddrs
};

export default key;
