import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { home_datas } from "../Action/apiAction.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [homeData, sethomeData] = useState({});

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    get_home_datas();
  }, []);

  const get_home_datas = async () => {
    let data = await home_datas();
    console.log(data?.status, data?.data, "home_datas");
    if (data?.status && data?.data) {
      sethomeData(data?.data);
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div
            className="box text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1 className="mb-5">
              Welcome To<span>DeFiQ</span>Staking Platform
            </h1>
            {!(
              walletConnection &&
              walletConnection.address &&
              walletConnection.address != "" &&
              walletConnection.address != null &&
              walletConnection.connect == "yes"
            ) ? (
              <button
                className="primary_btn mx-auto"
                data-bs-toggle="modal"
                data-bs-target="#connect_wallet_modal"
              >
                <div className="icon"></div>
                Connect Wallet
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* <section className="scale_box">
          <div className="container">
            <div className="bgbox">
              <div className="row">
                <div
                  className="col-lg-6 mb-lg-0 mb-4"
                  // data-aos="zoom-in" data-aos-duration="1000"
                >
                  <img
                    src={require("../assets/images/boximg.png")}
                    class="img-fluid leftimg revealing-image"
                    alt="img"
                  />
                </div>
                <div className="col-lg-6 ps-4">
                  <h2
                    className="h2tag mb-lg-5 mb-2"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    We Scale for you
                  </h2>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1100"
                    data-aos-delay="100"
                  >
                    <div>
                      <h5>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 0 }}
                        >
                          {({ isVisible }) => (
                            <div>
                              {isVisible ? (
                                <CountUp
                                  end={
                                    homeData && homeData?.totalUsers
                                      ? homeData?.totalUsers
                                      : 0
                                  }
                                />
                              ) : (
                                "k"
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </h5>
                      <p>All participants</p>
                    </div>
                    <img
                      src={require("../assets/images/s1.png")}
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                    <div>
                      <h5>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 0 }}
                        >
                          {({ isVisible }) => (
                            <div>
                              {isVisible ? (
                                <CountUp
                                  end={
                                    homeData && homeData?.totalUSDT
                                      ? homeData?.totalUSDT
                                      : 0
                                  }
                                />
                              ) : (
                                "k"
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </h5>
                      <p> Total Earned USDT </p>
                    </div>
                    <img
                      src={require("../assets/images/s2.png")}
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1300"
                    data-aos-delay="400"
                  >
                    <div>
                      <h5>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 0 }}
                        >
                          {({ isVisible }) => (
                            <div>
                              {isVisible ? (
                                <CountUp
                                  end={
                                    homeData && homeData?.userTwentyFour
                                      ? homeData?.userTwentyFour
                                      : 0
                                  }
                                />
                              ) : (
                                "k"
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </h5>
                      <p> Joined in 24 hours </p>
                    </div>
                    <img
                      src={require("../assets/images/s3.png")}
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="why">
          <div className="container">
            <div className="text-center mb-4">
              <h2
                className="h2tag  d-inline-block mx-auto"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                Why Choose DeFiQ Staking?
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6 d-flex">
                <div>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <div className="img_box">
                      <img
                        src={require("../assets/images/w1.png")}
                        class="img-fluid leftimg"
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>Decentralized and Trustworthy</h5>
                      <p>
                        Built on cutting-edge blockchain technology for
                        transparency and security.
                      </p>
                    </div>
                  </div>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <div className="img_box">
                      <img
                        src={require("../assets/images/w2.png")}
                        class="img-fluid leftimg"
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>Global Accessibility</h5>
                      <p>
                        Anyone, anywhere in the world can participate in our
                        Staking.
                      </p>
                    </div>
                  </div>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="700"
                  >
                    <div className="img_box">
                      <img
                        src={require("../assets/images/w3.png")}
                        class="img-fluid leftimg"
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5> Innovative Projects </h5>
                      <p>
                        Handpicked selection of the most promising blockchain
                        projects.
                      </p>
                    </div>
                  </div>
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                  >
                    <div className="img_box">
                      <img
                        src={require("../assets/images/w4.png")}
                        class="img-fluid leftimg"
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5> Token Utility </h5>
                      <p>
                        Early investors enjoy special perks, discounts, and
                        governance rights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-auto">
                <div
                  className="right_img"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <img
                    src={require("../assets/images/why1.png")}
                    class="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/howimg.png")}
                  class="img-fluid mt-auto"
                  alt="img"
                />
              </div>
              <div className="col-lg-6 m-auto">
                <h2
                  className="h2tag d-inline-block"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  How It Works
                </h2>
                <div class="accordion" id="accordionExample1">
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h2 class="accordion-header" id="headingOne1">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne1"
                        aria-expanded="true"
                        aria-controls="collapseOne1"
                      >
                        Connect Your Wallet
                      </button>
                    </h2>
                    <div
                      id="collapseOne1"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne1"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>Ensure you have a compatible wallet</li>
                          <li>Connect securely to our platform.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <h2 class="accordion-header" id="headingTwo2">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo2"
                        aria-expanded="false"
                        aria-controls="collapseTwo2"
                      >
                        Explore Projects
                      </button>
                    </h2>
                    <div
                      id="collapseTwo2"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo2"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>Ensure you have a compatible wallet</li>
                          <li>Connect securely to our platform.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                  >
                    <h2 class="accordion-header" id="headingThree3">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree3"
                        aria-expanded="false"
                        aria-controls="collapseThree3"
                      >
                        Participate in Staking
                      </button>
                    </h2>
                    <div
                      id="collapseThree3"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree3"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>Ensure you have a compatible wallet</li>
                          <li>Connect securely to our platform.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="900"
                  >
                    <h2 class="accordion-header" id="headingfour4">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefour4"
                        aria-expanded="false"
                        aria-controls="collapsefour4"
                      >
                        Token Distribution
                      </button>
                    </h2>
                    <div
                      id="collapsefour4"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfour4"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>Ensure you have a compatible wallet</li>
                          <li>Connect securely to our platform.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="join">
          <div className="container">
            <div className="bg" data-aos="fade-up" data-aos-duration="1000">
              <div className="row">
                <div className="col-lg-5">
                  <h3>
                    Join the <span> DeFiQ</span> community and be part of the
                    decentralized revolution.{" "}
                  </h3>
                  <button className="primary_btn mt-4">
                    <div className="icon"></div>
                    Explore Projects
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq" name="faq">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <div className="text-center">
                <h2 className="h2tag d-inline-block mb-4">
                  Frequently Asked Questions
                </h2>
              </div>
            </div>
            <div class="accordion" id="accordionExample">
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. HOW TO STAKE DEFIQ TOKEN?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      Buy tokens by connecting trust wallet with{" "}
                      <a target="_blank" href="https://staking.defiq.live/">
                        www.staking.defiq.live
                      </a>{" "}
                      and click staking option stake for minimum of 12 month
                      period of time.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. WHAT IS THE BENIFIT OF STAKING DEFIQ TOKEN?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      You get 3% to 7% monthly rewards up-to 12 months
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. CAN I CLAIM MY REWARDS EVERY MONTH?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      Yes, you can claim monthly rewards after 30 days.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    4. HOW I GET REFERRAL REWARDS?
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      You can claim referral rewards as USDT instant.
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    5. WHEN I WILL GET BACK MY PRINCIPLE?
                  </button>
                </h2>
                <div
                  id="collapsefive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      After 12 months you can claim principle 20 % as monthly
                      basis. To maintain the value and demand of DeFiQ Token
                      price on the Exchange
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
